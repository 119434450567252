<template>
  <ion-page>
    <ion-content color="light">
      <ion-grid>
        <ion-list lines="full" :inset="true" mode="ios">
          <ion-list-header style="font-size: 14pt; text-align: center">
            <ion-label>تقرير الساعة {{ hour }}</ion-label>
          </ion-list-header>
          <ion-item>
            <ion-label>عدد المشاركين</ion-label>
            <ion-input :class="{'invalid-input': !isValueValid(totalVotes)}" v-model="totalVotes" slot="end" class="custom-input" type="number" placeholder="العدد"></ion-input>
          </ion-item>
        </ion-list>
        <ion-button @click="submit" expand="full" class="login-button">حفظ</ion-button>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script setup lang="ts">
import {
  alertController,
  IonButton,
  IonContent,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonGrid,
  loadingController,
  onIonViewDidEnter,
  toastController
} from '@ionic/vue';
import {computed, ref, Ref} from "vue";
import axiosInstance from "@/plugins/axios";
import router from "@/router";
import {Position} from "@capacitor/geolocation";

const managerCoordinates: Ref<string | undefined> = ref();
const totalVotes = ref<string | undefined>()
const type = router.currentRoute.value.meta.type

const hour = computed(() => String(type).replace('_hour_report', ''))

const isValueValid = (value?: string) => value && (Number(value) <= 4250 && Number(value) >= 0)
const getRequiredList = async () => {
  const loading = await loadingController.create({
    message: 'جاري التحميل',
    duration: 3000,
  });

  await loading.present();
  try {
    const response = await axiosInstance.get(`/election/${type}`)
    totalVotes.value = response.data.total_votes
  } catch (e) {
    console.error(e)
    throw e
  } finally {
    await loading.dismiss()
  }
}

function getPosition(options?: PositionOptions): Promise<Position> {
  return new Promise((resolve, reject) =>
      navigator.geolocation.getCurrentPosition(resolve, reject, options)
  );
}

onIonViewDidEnter(async () => {
  const loading = await loadingController.create({
    message: 'جاري التحميل',
    duration: 3000,
  });

  try {
    await loading.present();
    const loc = await getPosition({timeout: 10000});
    await loading.dismiss()
    managerCoordinates.value = `${loc.coords.latitude},${loc.coords.longitude}`
    console.log(`${loc.coords.latitude},${loc.coords.longitude}`)
    await getRequiredList()
  } catch (e) {
    console.log(e)
    await loading.dismiss()
    const alert = await alertController.create({
      header: `حصل خطأ`,
      message: 'لم تمنح صلاحية الموقع',
      buttons: [
        {
          text: 'اعادة تحميل الصفحة',
          role: 'cancel',
          handler: () => {
            window.location.reload()
          }
        },
      ],
      backdropDismiss: false,
    });

    await alert.present();
    throw e
  }
})
const submit = async () => {
  const loading = await loadingController.create({
    message: 'جاري التحميل',
    duration: 3000,
  });

  await loading.present();
  try {
    if (!isValueValid(totalVotes.value)) {

      const alert = await alertController.create({
        header: 'خطأ في الادخال',
        message: 'الرجاء التأكد من جميع الادخالات يجب ان تتراوح بين 0 و 4250',
        buttons: ['اغلاق'],
        backdropDismiss: false
      });

      await alert.present();
      return
    }
    const loc = await getPosition({timeout: 10000});
    managerCoordinates.value = `${loc.coords.latitude},${loc.coords.longitude}`
    await axiosInstance.post(`/election/${type}`, {
      total_votes: totalVotes.value,
      coordinates: managerCoordinates.value,
    });
    const alert = await alertController.create({
      header: `تم بنجاح`,
      message: 'تم ارسال النتائج بنجاح',
      buttons: [
        {
          text: 'عودة للرئيسية',
          role: 'cancel',
          handler: () => {
            router.push('/home')
          }
        }
      ],
    });

    await alert.present();
  } catch (e) {
    const alert = await alertController.create({
      header: `حصل خطأ`,
      message: 'حصل خطأ',
      buttons: [
        {
          text: 'اعادة تحميل الصفحة',
          role: 'cancel',
          handler: () => {
            window.location.reload()
          }
        },
      ],
      backdropDismiss: false,
    });

    await alert.present();
    throw  e
  } finally {
    await loading.dismiss()
  }
}
</script>

<style scoped lang="scss">
ion-input {
  --padding-bottom: 10px;
  --padding-end: 10px;
  --padding-start: 10px;
  --padding-top: 10px;
  border: 1px solid #ccc; /* Add your preferred border style */
  padding: 5px; /* Adjust padding as needed */
  border-radius: 10px;
  max-width: 100px;
  text-align: center;
  min-width: 60px;
}

ion-label {
  margin-top: 0;
  padding-top: 0;
}

ion-thumbnail {
  --size: 30px;
  --border-radius: 14px;
}

.image {
  width: 30px;
  height: 30px;
  border-radius: 20%;
}

.invalid-input {
  border-color: red;
}

ion-list {
  text-align: center;
}

ion-list-header {
  font-size: 14pt;
  text-align: center;
}

ion-grid {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100%;
  justify-content: center;
}
</style>
