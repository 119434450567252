import type { RemovableRef } from '@vueuse/core'
import { defineStore } from 'pinia'
import {Manager} from "@/interfaces/manager";
import {getJSONStorage} from "@/plugins/json-storage";
import router from "@/router";
import axiosInstance from "@/plugins/axios";
interface AuthState {
  user: RemovableRef<Manager | undefined>
  loggedIn: boolean
  accessToken: RemovableRef<string | undefined>
  settings: {
    open_report: boolean
    hour_9_report: boolean
    hour_11_report: boolean
    hour_1_report: boolean
    hour_3_report: boolean
    hour_5_report: boolean
    electronic_report: boolean
    manual_report: boolean
    [key: string]: boolean
  }
}

export const useAuthStore = defineStore('auth', {
  state: () => ({
    loggedIn: Boolean(localStorage.getItem('userData')),
    user: getJSONStorage<Manager>('userData'),
    accessToken: getJSONStorage<string>('token'),
    settings: {
      open_report: false,
      hour_9_report: false,
      hour_11_report: false,
      hour_1_report: false,
      hour_3_report: false,
      hour_5_report: false,
      electronic_report: false,
      manual_report: false,
    },
  } as AuthState),
  actions: {
    async checkUser(): Promise<boolean> {
      if (!this.user)
        return false
      const response = await axiosInstance.get('/auth/me')
      if (response.data.data)
        this.setUser(response.data.data)
      else
        await this.logout()

      return this.loggedIn
    },
    setUser(userData: Manager) {
      this.user = userData
      this.loggedIn = true
      this.updateSettings()
    },
    async updateSettings() {
      await axiosInstance.get('/')
      setTimeout(this.updateSettings, 20000)
    },
    setAccessToken(accessToken: string) {
      this.accessToken = accessToken
    },
    async logout() {
      this.loggedIn = false
      this.accessToken = undefined
      this.user = undefined
      sessionStorage.clear()
      await router.push({ name: 'login' })
    },
  },
})
