<template>
  <ion-page>
    <ion-content :scroll-y="false" class="ion-padding">
      <div class="logo-container">
        <img src="/src/assets/logo.jpg" alt="Logo" class="logo">
      </div>

      <ion-item class="input-item">
        <ion-label position="floating">رقم الهاتف</ion-label>
        <ion-input v-model="phone"></ion-input>
      </ion-item>

      <ion-item class="input-item">
        <ion-label position="floating">كلمة المرور</ion-label>
        <ion-input type="password" v-model="password"></ion-input>
      </ion-item>

      <ion-button  @click="login" :disabled="isDisabled" expand="full" class="login-button">دخول</ion-button>
    </ion-content>
  </ion-page>
</template>

<script setup lang="ts">
import { IonInput, IonItem, IonContent, IonPage, IonButton, toastController } from '@ionic/vue';
import {computed, ref} from 'vue';
import axiosInstance from "@/plugins/axios";
import {useAuthStore} from "@/stores/authStore";
import router from "@/router";

const phone = ref<string>('');
const password = ref<string>('');

const login = async () => {
  try {
    const response = await axiosInstance.post('/auth/login', {
      phone: phone.value,
      password: password.value,
    })
    const toast = await toastController.create({
      message: 'تسجيل دخول ناجح',
      duration: 1500,
      color: 'success',
      position: 'bottom',
    });

    await toast.present();
    const auth = useAuthStore()
    auth.setAccessToken(response.data.data.token)
    auth.setUser(response.data.data)
    await router.replace('/home')
  } catch (e) {
    const toast = await toastController.create({
      message: 'تسجيل دخول خاطئ',
      duration: 1500,
      color: 'danger',
      position: 'bottom',
    });

    await toast.present();
  }
};
const isDisabled = computed(() => !password.value.length || !phone.value.length)
</script>

<style scoped>
.header-title {
  font-size: 1.5rem;
}

.logo-container {
  text-align: center;
  margin-bottom: 20px;
  animation: fadeIn 1s ease-out;
}

.logo {
  width: 200px;
  height: auto;
  border-radius: 50%;
}

.input-item {
  margin-bottom: 15px;
}

.login-button {
  background-color: #4CAF50;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
