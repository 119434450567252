import axios, {AxiosError} from "axios";
import { v4 as uuidv4 } from 'uuid'
import {useAuthStore} from "@/stores/authStore";


let instanceId = localStorage.getItem('instanceId')
if (!instanceId) {
    instanceId = uuidv4()
    localStorage.setItem('instanceId', instanceId!)
}

const axiosInstance = axios.create({
    baseURL: process.env.NODE_ENV === 'production' ? 'https://diyala.net/api/manager-api' : 'http://localhost:8000/manager-api',
    // baseURL: 'http://localhost:8000/manager-api',
    headers: {
        'Accept': 'application/json',
        'Accept-Language': 'ar',
        'iid': 'mobile app',
        'version': '1.0.0',
        'instance_id': instanceId,
        'device_id': instanceId,
        'system': 'web',
        'app_version': '1.0.0',
    },
})

axiosInstance.interceptors.request.use(
    config => {
        const authStore = useAuthStore()

        const accessToken = authStore.accessToken

        if (accessToken)
            config.headers.Authorization = `Bearer ${ accessToken }`

        return config
    },
    error => {
        return Promise.reject(error)
    },
)
axiosInstance.interceptors.response.use(
    response => {
        if (response.data.settings) {
            useAuthStore().settings = response.data.settings
        }
        return response
    },
    (error) => {
        if (error.response?.status && error.response?.status === 401) {
            const auth = useAuthStore()
            auth.logout()
        }
        return Promise.reject(error)
    },
)

export default axiosInstance
