<template>
  <ion-page>
    <ion-content class="ion-text-center" color="light">
      <div class="my-ion-content">
        <ion-card>
          <ion-card-title>
            {{ authStore.user!.name }}
          </ion-card-title>
          <br>
          <ion-card-title>
            {{ authStore.user!.center.name }}
          </ion-card-title>
          <ion-grid>
            <ion-row>
              <ion-col size="12" size-md="6">
                <ion-button :disabled="!authStore.settings.open_report" routerLink="/report/open" expand="full" color="warning">تقرير ساعة 8 (تقرير فتح المحطات)</ion-button>
                <ion-text v-if="!authStore.settings.open_report" color="danger">التقرير مغلق حالياً يرجى انتظار فتحه من الادارة</ion-text>
              </ion-col>
            </ion-row>
            <ion-row v-for="hour in [9, 11, 1, 3, 5]" :key="hour">
              <ion-col size="12" size-md="6">
                <ion-button :disabled="!authStore.settings[`hour_${hour}_report`]" :routerLink="`/report/${hour}_hour_report`" expand="full" color="primary">تقرير ساعة {{ hour }}</ion-button>
                <ion-text v-if="!authStore.settings[`hour_${hour}_report`]" color="danger">التقرير مغلق حالياً يرجى انتظار فتحه من الادارة</ion-text>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-card>
      </div>
    </ion-content>

  </ion-page>
</template>

<script setup lang="ts">
import {
  alertController,
  IonButton,
  IonContent,
  IonPage, loadingController, onIonViewDidEnter,
} from '@ionic/vue';
import {useAuthStore} from "@/stores/authStore";

const authStore = useAuthStore()

onIonViewDidEnter(async () => {
  const loading = await loadingController.create({
    message: 'جاري التحميل',
    duration: 3000,
  });

  await loading.present();
  navigator.geolocation.getCurrentPosition(async () => {
    await loading.dismiss()
  }, async () => {
    await loading.dismiss()
    const alert = await alertController.create({
      header: `حصل خطأ`,
      message: 'لم تمنح صلاحية الموقع',
      buttons: [
        {
          text: 'اعادة تحميل الصفحة',
          role: 'cancel',
          handler: () => {
            window.location.reload()
          }
        },
      ],
      backdropDismiss: false,
    });

    await alert.present();
  }, {timeout: 10000})
})
</script>

<style scoped lang="scss">
.my-ion-content {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100%;
  justify-content: center;
}
</style>
