<template>
  <ion-app>
    <ion-header>
      <ion-toolbar>
        <ion-title class="header-title">
          تسكين الانتخابي
        </ion-title>
        <ion-buttons slot="start">
          <ion-button v-show="hasBack" routerLink="/home">
            عودة
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-router-outlet />
    </ion-content>
  </ion-app>
</template>

<script setup lang="ts">
import {IonApp, IonButtons, IonButton, IonHeader, IonRouterOutlet, IonTitle, IonToolbar} from '@ionic/vue';
import {useRoute} from "vue-router";
import {useAuthStore} from "@/stores/authStore";
import router from "@/router";
import {computed} from "vue";
const { checkUser } = useAuthStore()
const route = useRoute()
const hasBack = computed(() => route.name!.toString().toLowerCase() != 'home' && route.name!.toString().toLowerCase() != 'login')
checkUser().then(isLogged => {
  if (route.meta.redirectIfLoggedIn && isLogged)
    router.push('/')
})
</script>
<style lang="scss">
.header-title {
  text-align: center;
}
ion-buttons {
  position: absolute;
}
</style>
