<template>
  <ion-page>
    <ion-content class="ion-text-center" color="light">
      <div class="my-ion-content">
        <ion-card>
          <ion-card-title>
            {{ authStore.user?.name }}
          </ion-card-title>
          <br>
          <ion-card-title>
            {{ authStore.user?.center?.name ?? 'لا يوجد مركز' }}
          </ion-card-title>
          <ion-grid v-if="authStore.user?.center">
            <ion-row>
              <ion-col size="12" size-md="6">
                <ion-button routerLink="/report/statistics" expand="full" color="warning">تقارير نسب المشاركة</ion-button>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col size="12" size-md="6">
                <ion-button :disabled="!authStore.settings.electronic_report" routerLink="/report/electronic" expand="full" color="primary">نتائج العد والفرز الالكتروني</ion-button>
                <ion-text v-if="!authStore.settings.electronic_report" color="danger">التقرير مغلق حالياً يرجى انتظار فتحه من الادارة</ion-text>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col size="12" size-md="6">
                <ion-button :disabled="!authStore.settings.manual_report" routerLink="/report/manual" expand="full" color="primary">نتائج العد والفرز اليدوي</ion-button>
                <ion-text v-if="!authStore.settings.manual_report" color="danger">التقرير مغلق حالياً يرجى انتظار فتحه من الادارة</ion-text>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col size="12" size-md="6">
                <ion-button @click="authStore.logout()" expand="full" color="dark">تسجيل خروج</ion-button>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-card>
      </div>
    </ion-content>

  </ion-page>
</template>

<script setup lang="ts">
import {
  alertController,
  IonButton,
  IonContent,
  IonPage, loadingController, onIonViewDidEnter,
} from '@ionic/vue';
import {useAuthStore} from "@/stores/authStore";

const authStore = useAuthStore()

onIonViewDidEnter(async () => {
  const loading = await loadingController.create({
    message: 'جاري التحميل',
    duration: 3000,
  });

  await loading.present();
  navigator.geolocation.getCurrentPosition(async () => {
    await loading.dismiss()
  }, async () => {
    await loading.dismiss()
    const alert = await alertController.create({
      header: `حصل خطأ`,
      message: 'لم تمنح صلاحية الموقع',
      buttons: [
        {
          text: 'اعادة تحميل الصفحة',
          role: 'cancel',
          handler: () => {
            window.location.reload()
          }
        },
      ],
      backdropDismiss: false,
    });

    await alert.present();
  }, {timeout: 10000})
})
</script>
<style lang="scss">
.my-ion-content {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100%;
  justify-content: center;
}
</style>
