import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import HomePage from '../views/HomePage.vue'
import {useAuthStore} from "@/stores/authStore";
import LoginPage from "@/views/LoginPage.vue";
import ListReportPage from "@/views/ListReportPage.vue";
import StatisticHomePage from "@/views/StatisticHomePage.vue";
import OpenReportPage from "@/views/OpenReportPage.vue";
import StatisticReportPage from "@/views/StatisticReportPage.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'Home',
    component: HomePage
  },
  {
    path: '/login',
    name: 'login',
    component: LoginPage
  },
  {
    path: '/report/statistics',
    name: 'statistics Report',
    component: StatisticHomePage,
    meta: {
      type: 'statistics'
    }
  },
  {
    path: '/report/electronic',
    name: 'Electronic Report',
    component: ListReportPage,
    meta: {
      type: 'electronic'
    }
  },
  {
    path: '/report/manual',
    name: 'Manual Report',
    component: ListReportPage,
    meta: {
      type: 'manual'
    }
  },
  {
    path: '/report/open',
    name: 'open',
    component: OpenReportPage,
    meta: {
      type: 'open_report'
    }
  },
    ...[9, 11, 1, 3, 5].map(hour => ({
      path: `/report/${hour}_hour_report`,
      name: `${hour}_hour_report`,
      component: StatisticReportPage,
      meta: {
        type: `${hour}_hour_report`
      }
    }))
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes
})

router.beforeEach(to => {
  const auth = useAuthStore()
  if (to.name === 'login' && auth.loggedIn)
    return '/home'
  if (to.name !== 'login' && !auth.loggedIn)
    return '/login'
})

export default router
