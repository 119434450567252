<template>
  <ion-page>
    <ion-content color="light">
      <ion-grid>
        <ion-list lines="full" :inset="true" mode="ios">
          <ion-list-header style="font-size: 14pt; text-align: center">
            <ion-label>تقرير ساعة 8 (تقرير فتح المحطات)</ion-label>
          </ion-list-header>
          <ion-item>
            <ion-label>العدد الكلي</ion-label>
            <ion-input :class="{'invalid-input': !isValueValid(totalStations)}" v-model="totalStations" slot="end" class="custom-input" type="number" placeholder="العدد"></ion-input>
          </ion-item>
          <ion-item>
            <ion-label>عدد المفتوحة</ion-label>
            <ion-input :class="{'invalid-input': !isValueValid(openStations)}" v-model="openStations" slot="end" class="custom-input" type="number" placeholder="العدد"></ion-input>
          </ion-item>
          <ion-item  v-if="totalStations != undefined && openStations != undefined && Number(totalStations) > Number(openStations)" v-for="(station, index) in Number(Number(totalStations ?? 0) - Number(openStations ?? 0))" :key="`notes_${station}`">
            <ion-textarea v-model="notes[index].value" :label="`سبب اغلاق المحطة ${station}`" label-placement="stacked" :placeholder="`سبب اغلاق المحطة ${station}`"></ion-textarea>
          </ion-item>
        </ion-list>
        <ion-button @click="submit" expand="full" class="login-button">حفظ</ion-button>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script setup lang="ts">
import {
  alertController,
  IonButton,
  IonContent,
  IonInput,
  IonItem,
  IonLabel,
  IonTextarea,
  IonGrid,
  IonList,
  IonPage,
  loadingController,
  onIonViewDidEnter,
} from '@ionic/vue';
import {ref, Ref} from "vue";
import axiosInstance from "@/plugins/axios";
import {Position} from "@capacitor/geolocation";
import router from "@/router";

const managerCoordinates: Ref<string | undefined> = ref();
const totalStations = ref<number | undefined>()
const openStations = ref<number | undefined>()
const notes: Ref<string>[] = [
    ref(''),
    ref(''),
    ref(''),
    ref(''),
    ref(''),
    ref(''),
    ref(''),
    ref(''),
    ref(''),
    ref(''),
]
const type = 'open_report'

const isValueValid = (value?: string | number, allowLength = false) => value && (allowLength || (Number(value) <= 15 && Number(value) >= 0))
const getRequiredList = async () => {
  const loading = await loadingController.create({
    message: 'جاري التحميل',
    duration: 3000,
  });

  await loading.present();
  try {
    const response = await axiosInstance.get(`/election/${type}`)
    totalStations.value = response.data.total_station_count
    openStations.value = response.data.open_station_count
    if (response.data.notes as string[])
      (response.data.notes as string[]).forEach((value, index ) => notes[index].value = value)
  } catch (e) {
    console.error(e)
    throw e
  } finally {
    await loading.dismiss()
  }
}

function getPosition(options?: PositionOptions): Promise<Position> {
  return new Promise((resolve, reject) =>
      navigator.geolocation.getCurrentPosition(resolve, reject, options)
  );
}

onIonViewDidEnter(async () => {
  const loading = await loadingController.create({
    message: 'جاري التحميل',
    duration: 3000,
  });

  try {
    await loading.present();
    const loc = await getPosition({timeout: 10000});
    await loading.dismiss()
    managerCoordinates.value = `${loc.coords.latitude},${loc.coords.longitude}`
    console.log(`${loc.coords.latitude},${loc.coords.longitude}`)
    await getRequiredList()
  } catch (e) {
    console.log(e)
    await loading.dismiss()
    const alert = await alertController.create({
      header: `حصل خطأ`,
      message: 'لم تمنح صلاحية الموقع',
      buttons: [
        {
          text: 'اعادة تحميل الصفحة',
          role: 'cancel',
          handler: () => {
            window.location.reload()
          }
        },
      ],
      backdropDismiss: false,
    });

    await alert.present();
    throw e
  }
})
const submit = async () => {
  const loading = await loadingController.create({
    message: 'جاري التحميل',
    duration: 3000,
  });

  await loading.present();
  try {
    if (!isValueValid(totalStations.value) || !isValueValid(openStations.value) || Number(openStations.value) > Number(totalStations.value) || notes.slice(0, Number(totalStations.value) - Number(openStations.value)).some(item => !isValueValid(item.value, true))) {

      const alert = await alertController.create({
        header: 'خطأ في الادخال',
        message: 'الرجاء التأكد من جميع الادخالات يجب ان تتراوح بين 0 و 15',
        buttons: ['اغلاق'],
        backdropDismiss: false
      });

      await alert.present();
      return
    }
    const loc = await getPosition({timeout: 10000});
    managerCoordinates.value = `${loc.coords.latitude},${loc.coords.longitude}`
    await axiosInstance.post(`/election/${type}`, {
      total_station_count: totalStations.value,
      open_station_count: openStations.value,
      notes: notes.slice(0, Number(totalStations.value) - Number(openStations.value)).map(item => item.value),
      coordinates: managerCoordinates.value,
    });
    const alert = await alertController.create({
      header: `تم بنجاح`,
      message: 'تم ارسال النتائج بنجاح',
      buttons: [
        {
          text: 'عودة للرئيسية',
          role: 'cancel',
          handler: () => {
            router.push('/home')
          }
        }
      ],
    });

    await alert.present();
  } catch (e) {
    const alert = await alertController.create({
      header: `حصل خطأ`,
      message: 'حصل خطأ',
      buttons: [
        {
          text: 'اعادة تحميل الصفحة',
          role: 'cancel',
          handler: () => {
            window.location.reload()
          }
        },
      ],
      backdropDismiss: false,
    });

    await alert.present();
    throw  e
  } finally {
    await loading.dismiss()
  }
}
</script>

<style scoped lang="scss">
ion-input {
  --padding-bottom: 10px;
  --padding-end: 10px;
  --padding-start: 10px;
  --padding-top: 10px;
  border: 1px solid #ccc; /* Add your preferred border style */
  padding: 5px; /* Adjust padding as needed */
  border-radius: 10px;
  max-width: 100px;
  text-align: center;
  min-width: 60px;
}

ion-label {
  margin-top: 0;
  padding-top: 0;
}

ion-thumbnail {
  --size: 30px;
  --border-radius: 14px;
}

.image {
  width: 30px;
  height: 30px;
  border-radius: 20%;
}

.invalid-input {
  border-color: red;
}

ion-list {
  text-align: center;
}

ion-list-header {
  font-size: 14pt;
  text-align: center;
}

ion-grid {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100%;
  justify-content: center;
}
</style>
