<template>
  <ion-page>
    <ion-content color="light" v-if="electionList.length">
      <ion-list lines="full" :inset="true" mode="ios">
        <ion-list-header>
          <ion-label>الائتلافات</ion-label>
        </ion-list-header>
        <ion-item v-for="item in electionList" :key="`list_${item.id}`">
          <ion-label slot="start">
            <ion-thumbnail>
              <img class="image" :src="item.image ?? noImage"/>
            </ion-thumbnail>
          </ion-label>
          <ion-label>{{ item.name }}</ion-label>
          <ion-input :class="{'invalid-input': !isValueValid(electionListForm[`list_${item.id}`].value)}" v-model="electionListForm[`list_${item.id}`].value" slot="end" class="custom-input" type="number" placeholder="عدد الاصوات"></ion-input>
        </ion-item>
      </ion-list>
      <ion-list v-for="list in starredList" :inset="true" mode="ios" class="list" :key="`list_starred_${list.id}`">
        <ion-list-header>
          <ion-label>مرشحين {{ list.name }}</ion-label>
        </ion-list-header>
        <div style="display: flex">
          <div>
            <ion-item v-for="item in list.candidates.slice(0, Math.ceil(list.candidates.length / 2))" :key="`candidate_${item.id}`">
              <ion-thumbnail slot="start" class="candidate-image">
                <img class="image" :src="item.image ?? noImage"/>
              </ion-thumbnail>
              <ion-label color="primary">
                {{ item.order }}
              </ion-label>
              <ion-input :class="{'invalid-input': !isValueValid(electionListForm[`candidate_${item.id}`].value)}" v-model="electionListForm[`candidate_${item.id}`].value" slot="end" class="custom-input" type="number" placeholder="عدد"></ion-input>
            </ion-item>
          </div>
          <div>
            <ion-item v-for="item in list.candidates.slice(Math.floor(list.candidates.length / 2))" :key="`candidate_${item.id}`">
              <ion-thumbnail slot="start" class="candidate-image">
                <img class="image" :src="item.image ?? noImage"/>
              </ion-thumbnail>
              <ion-label color="primary">
                {{ item.order }}
              </ion-label>
              <ion-input :class="{'invalid-input': !isValueValid(electionListForm[`candidate_${item.id}`].value)}"  v-model="electionListForm[`candidate_${item.id}`].value" slot="end" class="custom-input" type="number" placeholder="عدد"></ion-input>
            </ion-item>
          </div>
        </div>
      </ion-list>
      <ion-button @click="submit" expand="full" class="login-button">حفظ</ion-button>
    </ion-content>
  </ion-page>
</template>

<script setup lang="ts">
import {
  alertController,
  IonButton,
  IonContent,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  loadingController,
  onIonViewDidEnter,
  toastController
} from '@ionic/vue';
import {computed, ref, Ref} from "vue";
import axiosInstance from "@/plugins/axios";
import {ElectoralList} from "@/interfaces/electoral_list";
import router from "@/router";
import noImage from '@/assets/no-image.png'
import {Position} from "@capacitor/geolocation";
import {useAuthStore} from "@/stores/authStore";

const managerCoordinates: Ref<string | undefined> = ref();
const electionList: Ref<ElectoralList[]> = ref([]);
const electionListForm: { [key: string]: Ref<string> } = {}

const starredList = computed<ElectoralList[]>(() => electionList.value.filter(item => item.is_starred && item.candidates))

const type = router.currentRoute.value.meta.type ?? 'electronic'

const isValueValid = (value?: string) => value !== undefined && value !== '' && (Number(value) <= 4250 && Number(value) >= 0)
const isValid = () => {
  return Object.values(electionListForm).every(value => {
    return isValueValid(value.value)
  })
}

const getRequiredList = async () => {
  const loading = await loadingController.create({
    message: 'جاري التحميل',
    duration: 3000,
  });

  await loading.present();
  try {
    const response = await axiosInstance.get(`/election/${type}`)
    electionList.value = response.data.data
    electionList.value.forEach(list => {
      electionListForm[`list_${list.id}`] = ref(String(list.report_entry?.vote_count ?? ''))
      list.candidates?.forEach(candidate => {
        electionListForm[`candidate_${candidate.id}`] = ref(String(candidate.report_entry?.vote_count ?? ''))
      })
    })
  } catch (e) {
    console.error(e)
    throw e
  } finally {
    await loading.dismiss()
  }
}

function getPosition(options?: PositionOptions): Promise<Position> {
  return new Promise((resolve, reject) =>
      navigator.geolocation.getCurrentPosition(resolve, reject, options)
  );
}

onIonViewDidEnter(async () => {
  const loading = await loadingController.create({
    message: 'جاري التحميل',
    duration: 3000,
  });

  try {
    await loading.present();
    const loc = await getPosition({timeout: 10000});
    await loading.dismiss()
    managerCoordinates.value = `${loc.coords.latitude},${loc.coords.longitude}`
    console.log(`${loc.coords.latitude},${loc.coords.longitude}`)
    await getRequiredList()
  } catch (e) {
    console.log(e)
    await loading.dismiss()
    const alert = await alertController.create({
      header: `حصل خطأ`,
      message: 'لم تمنح صلاحية الموقع',
      buttons: [
        {
          text: 'اعادة تحميل الصفحة',
          role: 'cancel',
          handler: () => {
            window.location.reload()
          }
        },
      ],
      backdropDismiss: false,
    });

    await alert.present();
    throw e
  }
})
const auth = useAuthStore()
const submit = async () => {
  const loading = await loadingController.create({
    message: 'جاري التحميل',
    duration: 3000,
  });

  await loading.present();
  try {
    if (!isValid()) {

      const alert = await alertController.create({
        header: 'خطأ في الادخال',
        message: 'الرجاء التأكد من جميع الادخالات يجب ان تتراوح بين 0 و 4250',
        buttons: ['اغلاق'],
        backdropDismiss: false
      });

      await alert.present();
      return
    }
    const loc = await getPosition({timeout: 10000});
    managerCoordinates.value = `${loc.coords.latitude},${loc.coords.longitude}`
    const payload: Array<any> = Object.keys(electionListForm).map(key => {
      const [type, id] = key.split('_')
      return {
        [`${type}_id`]: id,
        vote_count: electionListForm[key].value,
      };
    })
    if (auth.user!.center.voter_count) {
      const sumOfVoteCounts = payload.filter(item => !!item.list_id).reduce((sum, {vote_count}) => sum + Number(vote_count), 0);
      if (sumOfVoteCounts > auth.user!.center.voter_count) {
        const alert = await alertController.create({
          header: 'خطأ في الادخال',
          message: `عدد الاصوات اكثر من عدد المقترعين في المركز ${auth.user!.center.voter_count}`,
          buttons: ['اغلاق'],
          backdropDismiss: false
        });

        await alert.present();
        await loading.dismiss();
        return
      }
    }
    await axiosInstance.post(`/election/${type}`, {
      payload,
      coordinates: managerCoordinates.value,
    });
    const alert = await alertController.create({
      header: `تم بنجاح`,
      message: 'تم ارسال النتائج بنجاح',
      buttons: [
        {
          text: 'عودة للرئيسية',
          role: 'cancel',
          handler: () => {
            router.push('/home')
          }
        }
      ],
    });

    await alert.present();
  } catch (e) {
    const alert = await alertController.create({
      header: `حصل خطأ`,
      message: 'حصل خطأ',
      buttons: [
        {
          text: 'اعادة تحميل الصفحة',
          role: 'cancel',
          handler: () => {
            window.location.reload()
          }
        },
      ],
      backdropDismiss: false,
    });

    await alert.present();
    throw  e
  } finally {
    await loading.dismiss()
  }
}
</script>

<style scoped lang="scss">
ion-input {
  --padding-bottom: 10px;
  --padding-end: 10px;
  --padding-start: 10px;
  --padding-top: 10px;
  border: 1px solid #ccc; /* Add your preferred border style */
  padding: 5px; /* Adjust padding as needed */
  border-radius: 10px;
  max-width: 100px;
  text-align: center;
  min-width: 60px;
}

ion-label {
  margin-top: 0;
  padding-top: 0;
}

ion-thumbnail {
  --size: 30px;
  --border-radius: 14px;
}

.image {
  width: 30px;
  height: 30px;
  border-radius: 20%;
}

.invalid-input {
  border-color: red;
}
ion-list {
  text-align: center;
}
ion-list-header {
  font-size: 14pt;
  text-align: center;
}

@media only screen and (max-width: 370px) {
.candidate-image {
  display: none;
}
}
</style>
