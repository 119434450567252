import type { RemovableRef, StorageLike } from '@vueuse/core'
import {useStorage} from "@vueuse/core";

export function getJSONStorage<T>(key: string, defaultValue: any = null, storageType: StorageLike = localStorage): RemovableRef<T> {
  return useStorage(key, defaultValue, storageType, {
    serializer: {
      read: (v: string | null) => v ? JSON.parse(v) : null,
      write: (v: string) => JSON.stringify(v),
    },
  })
}
